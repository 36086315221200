import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { BookingsList } from './BookingsList'
import {
  BookingFilters,
  BookingsPayload,
  BookingStatus,
  DateRange,
  RideCategory,
  ServiceScope,
  ServiceType,
  TimeState,
  useAutosuggestQuery,
  useBookingListQuery,
} from '../../generated/graphql'
import Container from 'design-system/src/components/Container/Container'
import { Info } from '../Info'
import LoadingSpinner from 'design-system/src/components/LoadingSpinner/LoadingSpinner'
import Pagination from 'design-system/src/components/Pagination/Pagination'
import styles from './index.module.css'

interface IBookingListContainer {
  bookingNumber?: BookingFilters['bookingNumber']
  category: RideCategory
  dateRange?: DateRange
  page: number
  pageSize: number
  pollInterval: number
  searchServiceLocation?: string
  searchTerm?: BookingFilters['matching']
  setPage: Dispatch<SetStateAction<number>>
  setServiceLocationData: Dispatch<SetStateAction<string[]>>
  suggestLocation: string
  timeState: TimeState
}

export const BookingsListContainer = ({
  bookingNumber,
  category,
  dateRange,
  page,
  pageSize,
  pollInterval,
  searchServiceLocation,
  searchTerm,
  setPage,
  setServiceLocationData,
  suggestLocation,
  timeState,
}: IBookingListContainer) => {
  const getOffset = () => (page - 1) * pageSize
  const { data, error, loading, refetch } = useBookingListQuery({
    variables: {
      bookingNumber,
      category,
      dateRange,
      matching: searchTerm ?? searchServiceLocation,
      offset: getOffset(),
      pageSize,
      serviceScope: ServiceScope.All,
      serviceType: ServiceType.Ride,
      status: [BookingStatus.All],
      timeState,
    },
    pollInterval,
  })

  const {
    data: {
      // @ts-expect-error - Property 'autosuggest' does not exist on type 'AutosuggestQuery | never[]
      autosuggest: { items = [] } = {},
    } = [],
    error: serviceLocationError,
    loading: serviceLocationIsLoading,
  } = useAutosuggestQuery({
    variables: {
      suggestLocation: suggestLocation ?? '',
    },
  })

  useEffect(() => {
    if (!serviceLocationIsLoading && items.length > 0) {
      setServiceLocationData(items)
    }
  }, [items, serviceLocationIsLoading, setServiceLocationData])

  if (error?.message !== undefined && error?.graphQLErrors !== undefined) {
    if (error?.message === 'GraphQL error: User not authenticated') {
      return <LoadingSpinner />
    } else {
      return <Info type="error" message={error?.message} />
    }
  }

  if (
    serviceLocationError?.message !== undefined &&
    serviceLocationError?.graphQLErrors !== undefined
  ) {
    if (
      serviceLocationError?.message ===
      'GraphQL serviceLocationError: User not authenticated'
    ) {
      return <LoadingSpinner />
    } else {
      return <Info type="error" message={serviceLocationError?.message} />
    }
  }

  return loading ? (
    <LoadingSpinner fullParent={true} />
  ) : (
    <>
      <div className={styles.rowContainer}>
        <Container className={styles.messageContainer}>
          <p>{data?.bookings?.pageInfo?.totalCount} results</p>
        </Container>
        <Pagination
          currentPage={page}
          setPage={setPage}
          totalPages={data?.bookings?.pageInfo?.totalPages ?? 0}
        />
        <Container className={styles.refreshContainer}>
          <button
            type="reset"
            data-type="tertiary"
            onClick={async () => await refetch()}
          >
            Refresh
          </button>
        </Container>
      </div>
      <BookingsList
        category={category}
        data={(data?.bookings?.items as unknown) as BookingsPayload['items']}
      />
      <Pagination
        currentPage={page}
        setPage={setPage}
        totalPages={data?.bookings?.pageInfo?.totalPages ?? 0}
      />
    </>
  )
}
