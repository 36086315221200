import React, { useState, useEffect, ChangeEvent } from 'react'
import { DateTime } from 'luxon'
import Router, { useRouter } from 'next/router'
import classnames from 'classnames'

import { TimeState } from 'src/generated/graphql'
import { BookingsListContainer } from '../../src/components/BookingsList'
import {
  DropdownSelect,
  SearchInput,
} from 'design-system/src/components/Input/Input'
import { DataList } from '../../src/components/DataList'
import Container from 'design-system/src/components/Container/Container'
import DatePicker from 'design-system/src/components/DatePicker/DatePicker'
import { RideCategory, BookingFilters } from '../../src/generated/graphql'
import styles from './index.module.css'
import { handleRouterState } from '../../src/components/BookingsList/handleRouterState'

const PAGE_SIZE = parseInt(process.env.NEXT_PUBLIC_PAGE_SIZE ?? '')
const POLL_INTERVAL = parseInt(process.env.NEXT_PUBLIC_POLL_INTERVAL ?? '')

const BookingsList = () => {
  const router = useRouter()

  const [{ searchTerm, bookingNumber }, setState] = useState<{
    bookingNumber: BookingFilters['bookingNumber'] | undefined
    searchTerm: BookingFilters['matching'] | undefined
  }>({
    bookingNumber: undefined,
    searchTerm: undefined,
  })

  const [date, setDate] = useState<null | Date>(null)
  const [category, setCategory] = useState(RideCategory.Ondemand)
  const [page, setPage] = useState(1)
  const [serviceLocation, setServiceLocationInput] = useState<
    string | undefined
  >(undefined)
  const [serviceLocationData, setServiceLocationData] = useState<string[]>([])
  const [searchServiceLocation, setSearchServiceLocation] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    const handlePageFromUrl = (): void => {
      if (router.isReady && router.query.page) {
        const pageNumberInUrl = parseInt(router.query.page as string)
        if (page !== pageNumberInUrl) {
          handleRouterState(router, category, page)
        }
      }
    }
    handlePageFromUrl()
  }, [page, router, router.isReady, router.query.page, category])

  const onBookingNumberSearchChange = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const eventTargetValue = (event.target as HTMLInputElement).value
    setState({
      searchTerm,
      bookingNumber: eventTargetValue,
    })
  }

  const onSearchTermChange = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const searchTermValue = (event.target as HTMLInputElement).value
    setState({
      bookingNumber,
      searchTerm: searchTermValue,
    })
    setPage(1)
  }

  const onBookingNumberSearchReset = () => {
    setState({ searchTerm, bookingNumber: '' })
    setPage(1)
  }

  const onSearchTermReset = () => {
    setState({
      bookingNumber,
      searchTerm: '',
    })
    setServiceLocationInput(undefined)
    setSearchServiceLocation(undefined)
    setServiceLocationData([])
    setPage(1)
  }

  const onServiceLocationInputReset = () => {
    setServiceLocationInput(undefined)
    setSearchServiceLocation(undefined)
    setServiceLocationData([])
    setPage(1)
  }

  const onServiceLocationTypeInInput = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const searchTermValue = (event.target as HTMLInputElement).value
    if (searchTermValue === '') {
      setServiceLocationInput(undefined)
      setSearchServiceLocation(undefined)
      setServiceLocationData([])
    }
  }

  const onClearFilter = () => {
    handleRouterState(router, RideCategory.Ondemand, 1)
    setState({
      bookingNumber: undefined,
      searchTerm: undefined,
    })
    setCategory(RideCategory.Ondemand)
    setDate(null)
    setServiceLocationInput(undefined)
    setServiceLocationData([])
    setSearchServiceLocation(undefined)
    setPage(1)
  }

  const handleOnChangeSelect = (
    event: React.SyntheticEvent<HTMLSelectElement>
  ) => {
    const eventTargetValue = (event.target as HTMLSelectElement).value
    handleRouterState(router, eventTargetValue, 1)
    setCategory(eventTargetValue as RideCategory)
    setPage(1)
  }

  const handleOnDateSelect = (selectedDate: Date) => {
    setDate(selectedDate)
    setPage(1)
  }

  const assignBookingNumber = () =>
    bookingNumber !== '' ? bookingNumber : undefined

  const assignSearchTerm = () => (searchTerm !== '' ? searchTerm : undefined)

  const onSearchServiceLocationChange = (
    event: ChangeEvent<HTMLInputElement> & {
      target: { list: { options: HTMLOptionsCollection | undefined } }
    }
  ) => {
    const options = event?.target?.list?.options
    const serviceLocationInputValue =
      ((event.target as unknown) as HTMLInputElement).value ?? ''
    setPage(1)
    setServiceLocationInput(serviceLocationInputValue)
    if (options) {
      const serviceLocationDataListValuesArray = Array.from(options)
      let matchingServiceLocationDataListValueWithSelectedOption: string[] = []

      if (serviceLocationDataListValuesArray.length > 0) {
        matchingServiceLocationDataListValueWithSelectedOption = serviceLocationDataListValuesArray
          .filter((option: HTMLOptionElement) =>
            serviceLocationInputValue.toLowerCase() ===
            option?.innerText?.toLowerCase()
              ? option?.innerText?.toLowerCase()
              : ''
          )
          .map(({ value }: HTMLOptionElement) => value)
      }

      if (matchingServiceLocationDataListValueWithSelectedOption.length === 1) {
        setSearchServiceLocation(
          matchingServiceLocationDataListValueWithSelectedOption[0]
        )
      }
    }
    return null
  }

  Router.events.on('routeChangeStart', (url): void => {
    if (url.includes('page=')) {
      const parsedUrlWithPageParam = parseInt(url.split('page=').pop())
      if (parsedUrlWithPageParam !== 1) {
        setPage(parsedUrlWithPageParam)
      }
    }
    if (url.includes('filter=')) {
      const parsedUrlWithFilterParam = url.split('filter=').pop()
      if (parsedUrlWithFilterParam !== category) {
        if (Object.values(RideCategory).includes(parsedUrlWithFilterParam)) {
          setCategory(parsedUrlWithFilterParam)
        } else {
          setCategory(RideCategory.Ondemand)
        }
      }
    }
  })

  return (
    <>
      <Container className={styles.titleContainer}>
        <h1 className={styles.sectionTitle}>On Demand Bookings</h1>
      </Container>
      <Container className={styles.filtersContainer}>
        <div className={styles.filtersRow}>
          <form className={styles.searchInputForm}>
            <SearchInput
              autoComplete="new-password"
              className={classnames(
                styles.searchInput,
                styles.searchInputWithNoIcon
              )}
              label="booking-number-search"
              placeholder="Search by booking number"
              onClick={(e) => {
                e.preventDefault()
              }}
              onChange={onBookingNumberSearchChange}
              onReset={onBookingNumberSearchReset}
              value={bookingNumber ?? undefined}
            />
            <SearchInput
              autoComplete="new-password"
              className={classnames(
                styles.searchInput,
                styles.searchInputWithNoIcon
              )}
              label="free-text-search"
              placeholder="Name, location or phone number"
              onClick={(e) => {
                e.preventDefault()
              }}
              onChange={onSearchTermChange}
              onReset={onSearchTermReset}
              value={searchTerm ?? undefined}
            />
            <SearchInput
              autoComplete="new-password"
              className={classnames(
                styles.searchInput,
                styles.searchInputWithNoIcon
              )}
              label="service-location"
              list="service-location-list"
              onChange={onSearchServiceLocationChange}
              onReset={onServiceLocationInputReset}
              placeholder="City name"
              value={serviceLocation ?? ''}
              onInput={onServiceLocationTypeInInput}
            />
            <DataList serviceLocationData={serviceLocationData ?? []} />
          </form>
          <DropdownSelect
            className={styles.categorySelector}
            containerClassName={styles.selectorWrapper}
            id="category-selector"
            key="category-selector"
            label="Ride Category"
            name="category-selector"
            onChange={handleOnChangeSelect}
            value={category}
          >
            {Object.values(RideCategory).map(
              (currentCategory: string, idx: React.Key) => (
                <option key={idx} value={currentCategory}>
                  {currentCategory}
                </option>
              )
            )}
          </DropdownSelect>
          <DatePicker
            containerClassName={styles.datePicker}
            id="date"
            label="Date"
            minDate={DateTime.now().minus({ year: 1 }).toJSDate()}
            name="date"
            onDateSelect={handleOnDateSelect}
            value={date ?? undefined}
          />
          <button
            className={styles.clearFiltersCTA}
            data-type="tertiary"
            onClick={onClearFilter}
            type="button"
          >
            Clear filters
          </button>
        </div>
      </Container>
      {date ? (
        <BookingsListContainer
          bookingNumber={assignBookingNumber()}
          category={category}
          dateRange={{
            startDate: DateTime.fromJSDate(date).startOf('day').toISO(),
            endDate: DateTime.fromJSDate(date).endOf('day').toISO(),
          }}
          page={page}
          pageSize={PAGE_SIZE}
          pollInterval={POLL_INTERVAL}
          searchServiceLocation={searchServiceLocation}
          searchTerm={assignSearchTerm()}
          setPage={setPage}
          setServiceLocationData={setServiceLocationData ?? []}
          suggestLocation={serviceLocation ?? ''}
          timeState={TimeState.All}
        />
      ) : (
        <BookingsListContainer
          bookingNumber={assignBookingNumber()}
          category={category}
          page={page}
          pageSize={PAGE_SIZE}
          pollInterval={POLL_INTERVAL}
          searchServiceLocation={searchServiceLocation}
          searchTerm={assignSearchTerm()}
          setPage={setPage}
          setServiceLocationData={setServiceLocationData ?? []}
          suggestLocation={serviceLocation ?? ''}
          timeState={TimeState.All}
        />
      )}
    </>
  )
}

export default BookingsList
