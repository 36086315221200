import React from 'react'

export const DataList = ({
  serviceLocationData,
}: {
  serviceLocationData: string[]
}) => {
  if (serviceLocationData?.length > 0) {
    return (
      <datalist id="service-location-list">
        {serviceLocationData?.map((location: string, key: React.Key) => (
          <option key={key} value={location}>
            {location}
          </option>
        ))}
      </datalist>
    )
  }
  return null
}
