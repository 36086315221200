import React from 'react'
import { IntlProvider } from 'react-intl'

import Table from 'design-system/src/components/Table/Table'
import styles from './index.module.css'
import Container from 'design-system/src/components/Container/Container'
import { convertMetersToKm } from 'design-system/src/utils/format-distance/'
import StatusItem from 'design-system/src/components/StatusItem'
import { BookingsPayload, RideCategory } from '../../generated/graphql'
import { isRideType } from '../../../typings/rideCasting'
import {
  DEFAULT_LOCALE,
  MESSAGES,
  getBookingsListTableHeaders,
} from '../../constants'
import { Info } from '../Info'
import { ExternalLink } from '../ExternalLink'
import { getBookingDateTimeAndZone } from '../../lib/date'

const TablePriceInfo = ({ priceAndCurrency }: { priceAndCurrency: string }) => (
  <>{priceAndCurrency ? <p>{priceAndCurrency}</p> : null}</>
)

export const BookingsList = ({
  data,
  category,
}: {
  data: BookingsPayload['items']
  category: RideCategory
}) => {
  if (!data) {
    return <Info message="No bookings found at all" type="info" />
  }
  const tableHeaders =
    category === RideCategory.Ondemand
      ? getBookingsListTableHeaders('Booked At')
      : getBookingsListTableHeaders('Starts At')
  return (
    <IntlProvider locale={DEFAULT_LOCALE} messages={MESSAGES.EN}>
      <>
        {data.length > 0 ? (
          <Container className={styles.fullWidth}>
            <Table key="table" tableHeaders={tableHeaders}>
              {data.map(
                (
                  {
                    id,
                    number,
                    booker: { uuid },
                    services: [service],
                    price,
                    status: bookingStatus,
                  },
                  idx
                ) => {
                  if (isRideType(service)) {
                    const {
                      category,
                      chauffeur,
                      date,
                      distance,
                      dropoff,
                      passengers,
                      pickup,
                      status,
                    } = service

                    return (
                      <tr key={idx}>
                        <td data-thead-text="bookingNumberHeading">
                          {category === RideCategory.Ondemand ? (
                            <a
                              href={`/details/${id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {number}
                            </a>
                          ) : (
                            <ExternalLink
                              {...{
                                linkTo: id,
                                section: 'tours',
                              }}
                            >
                              {number}
                            </ExternalLink>
                          )}
                        </td>
                        <td>
                          <p className={styles.rideCategory}>{category}</p>
                        </td>
                        <td>
                          <p>{getBookingDateTimeAndZone(date)}</p>
                        </td>
                        <td>
                          {category === RideCategory.Ondemand ? (
                            status && <StatusItem status={status} type="ride" />
                          ) : (
                            <StatusItem status={bookingStatus} type="booking" />
                          )}
                        </td>
                        <td>
                          <p>{pickup.address}</p>
                        </td>
                        <td>
                          <p>{dropoff?.address}</p>
                        </td>
                        {Object.values(passengers).map(
                          ({ firstName, lastName, phoneNumber }, idx) => (
                            <td key={idx}>
                              <>
                                <ExternalLink
                                  {...{
                                    linkTo: uuid as string,
                                    section: 'passengers',
                                  }}
                                >
                                  {`${firstName} ${lastName}`}
                                </ExternalLink>
                                <span>{phoneNumber}</span>
                              </>
                            </td>
                          )
                        )}
                        <td>
                          {distance ? (
                            <p> {convertMetersToKm(distance)} km</p>
                          ) : null}
                        </td>
                        <td>
                          <TablePriceInfo
                            priceAndCurrency={`${price.totalAmount / 100} ${
                              price.currency
                            }`}
                          />
                        </td>
                        <td>
                          {chauffeur && (
                            <>
                              <ExternalLink
                                {...{
                                  linkTo: chauffeur.companyId as string,
                                  section: 'companies',
                                }}
                              >
                                {`${chauffeur.firstName} ${chauffeur.lastName}`}
                              </ExternalLink>
                              <span>{chauffeur.phoneNumber}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                }
              )}
            </Table>
          </Container>
        ) : (
          <Info message="No bookings to be displayed" type="info" />
        )}
      </>
    </IntlProvider>
  )
}
