import { datadogRum } from '@datadog/browser-rum'
import { NextRouter } from 'next/router'

import { RideCategory } from 'src/generated/graphql'

export const handleRouterState = (
  router: NextRouter,
  category: RideCategory | string,
  page: number
) => {
  router
    .push(
      router.asPath,
      {
        query: {
          page,
          filter: category,
        },
      },
      { shallow: true }
    )
    .catch((e) => datadogRum.addError(e, undefined, 'source'))
}
